var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","pa-0":"","relative":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v(" Start of Day "),_c('v-spacer')],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title pb-0"},[_c('v-calendar-field',{attrs:{"id-val":"sodDate","label":"SOD Date"},model:{value:(_vm.sodDate),callback:function ($$v) {_vm.sodDate=$$v},expression:"sodDate"}}),_c('v-flex',{attrs:{"xs2":""}},[_c('search-button',{attrs:{"permission":"sod-read"},on:{"action":function($event){return _vm.get()}}},[_vm._v(" Search ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.form.items.data,"search":_vm.search,"options":_vm.pagination,"sort-by":"id","footer-props":_vm.footerProps,"server-items-length":_vm.form.items.meta.total,"loading":_vm.form.loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + _vm.form.items.meta.from))]),_c('td',{staticClass:"text-xs-left"},[_c('strong',{staticStyle:{"display":"block","margin-top":"5px"}},[_vm._v(_vm._s(item.sod_date))]),_c('small',{staticStyle:{"color":"#888"}},[_vm._v(_vm._s(item.sod_format))])]),_c('td',{staticClass:"text-xs-left"},[(item.is_eod === 2 || item.is_eod === 1)?_c('v-chip',{staticClass:"success white--text",attrs:{"label":"","small":""}},[_vm._v("DONE ")]):(item.is_eod === 0)?_c('v-chip',{staticClass:"error white--text",attrs:{"label":"","small":""}},[_vm._v("PENDING ")]):(item.is_eod === 3)?_c('v-chip',{staticClass:"warning white--text",attrs:{"small":""}},[_vm._v("HOLDING ")]):_vm._e()],1),_c('td',[(item.is_eod === 0)?_c('v-chip',{staticClass:"error white--text",attrs:{"small":"","label":""}},[_vm._v(" Not Ready ")]):(
                    item.is_eod === 1 && parseInt(item.collected_amount) === 0
                  )?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Not required ")]):(
                    item.is_eod === 1 && item.account_posted_at === null
                  )?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Ready to Post ("+_vm._s(parseFloat(item.collected_amount).numberFormat())+") ")]):_c('div',[_c('p',{staticStyle:{"margin-top":"10px","margin-bottom":"0"}},[_c('strong',[_c('v-icon',{attrs:{"size":"small","color":"success"}},[_vm._v("check_circle")]),_vm._v(" "+_vm._s(parseFloat(item.collected_amount).numberFormat())+" ")],1)]),_c('p',{staticStyle:{"font-size":"13px","color":"#555"}},[_vm._v(" Posted by "+_vm._s(item.accountant)+" on "+_vm._s(item.account_posted_at)+" ")])])],1),_c('td',{staticClass:"text-xs-left"},[(item.is_eod === 0)?_c('v-chip',{staticClass:"error white--text",attrs:{"small":"","label":""}},[_vm._v(" Not Ready ")]):(item.is_eod !== 0 && item.invoice_posting === 0)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Ready to Post ")]):(item.is_eod !== 0 && item.invoice_posting === 2)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Not required ")]):(item.is_eod !== 0 && item.invoice_posting === 1)?_c('div',[_c('p',{staticStyle:{"font-size":"13px","color":"#555","display":"flex","line-height":"1.5"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"success"}},[_vm._v(" check_circle ")]),_c('span',[_vm._v(" Posted by "+_vm._s(item.accountant)+" on "),_c('br'),_vm._v(" "+_vm._s(item.invoice_posted_at))])],1)]):_vm._e()],1),_c('td',{staticClass:"text-xs-right"},[_c('view-button',{attrs:{"label":(item.is_eod &&
                      item.account_posted_at === null &&
                      parseInt(item.collected_amount) !== 0) ||
                    (item.is_eod && item.invoice_posting === 0)
                      ? 'Review & Post'
                      : 'Review',"permission":"sod-read"},nativeOn:{"click":function($event){return _vm.viewStatement(item.sod_date)}}})],1)])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"300px"},model:{value:(_vm.form.dialog),callback:function ($$v) {_vm.$set(_vm.form, "dialog", $$v)},expression:"form.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"title"},[_vm._v("Add/Update")])]),_c('v-card-text',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}},nativeOn:{"keydown":function($event){return _vm.form.errors.clear($event.target.name)}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-calendar-field',{attrs:{"id-val":"start_date","label":"Start Date"},model:{value:(_vm.form.sod_date),callback:function ($$v) {_vm.$set(_vm.form, "sod_date", $$v)},expression:"form.sod_date"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","outlined":""},on:{"click":function($event){(_vm.form.dialog = false), _vm.form.reset()}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"success","outlined":"","disabled":_vm.disabledBtn},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }